import type { FC } from "react";
import React, { useState } from "react";
import { Switch } from "react-aria-components";
import QuestionnaireCubit from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import { QuestionnaireType } from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import { StorageController } from "src/state/StorageBloc/StorageBloc";
import { useBloc } from "src/state/state";
import { SectionTitle } from "src/ui/components/CustomQuestionnairePreview/CustomQuestionnairePreview";
import { formatPlaceholders } from "src/ui/components/CustomQuestionnairePreview/helpers";
import Link from "src/ui/components/Link/Link";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider
} from "src/ui/styled/X";

const CustomQuestionnairePreviewSteps: FC<{
  accordionProps: Record<string, unknown>;
}> = ({ accordionProps }) => {
  const [
    { fields, logicSteps, activeField },
    { parseAnswerOnlyValue, isFinishStep }
  ] = useBloc(QuestionnaireCubit);
  const hash = activeField?.ref;

  const [autoSkip] = useState(
    StorageController.getItem("debug.autoSkip") === "true"
  );

  const [hideUnused, setHideUnused] = useState(true);

  const isSupportedType = (itemType: string): boolean => {
    const values = Object.values(QuestionnaireType);
    return Object.keys(QuestionnaireType)
      .map((_, i) => values[i] as string)
      .includes(itemType);
  };

  const getAnswerValue = (id: string): string => {
    const { value } = parseAnswerOnlyValue(id);
    return value !== undefined ? JSON.stringify(value, null, 2) : "--";
  };

  const toggleAutoSkip = (): void => {
    StorageController.setItem("debug.autoSkip", autoSkip ? "false" : "true");
    window.location.reload();
  };

  return (
    <Accordion {...accordionProps}>
      <AccordionSummary>
        <SectionTitle>
          Steps ({logicSteps.length}/{fields.length})
        </SectionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <Box style={{ width: "100%" }}>
          <Switch isSelected={autoSkip} onChange={(): void => toggleAutoSkip()}>
            Auto-Skip
          </Switch>
          <Switch
            isSelected={hideUnused}
            onChange={(): void => setHideUnused(!hideUnused)}
          >
            Hide Unused Steps
          </Switch>

          <hr />
          <span>
            Showing:{" "}
            <b>
              {
                fields.filter((f) =>
                  hideUnused ? logicSteps.includes(f.ref) : true
                ).length
              }
            </b>
            /{fields.length}
          </span>
          <hr />
          {fields.map((item) => (
            <div
              key={item.id}
              style={{
                paddingTop: 15,
                ...(hash === item.ref
                  ? {
                      borderLeft: `4px solid green`,
                      paddingLeft: 10
                    }
                  : {}),
                ...(isFinishStep(item)
                  ? {
                      borderRight: `4px solid red`,
                      paddingRight: 10
                    }
                  : {}),
                ...(logicSteps.includes(item.ref)
                  ? {}
                  : {
                      opacity: 0.3,
                      display: hideUnused ? "none" : "block"
                    })
              }}
            >
              <Link style={{ color: "#000" }} to={`#${item.ref}`}>
                <h5>{formatPlaceholders(item.title)}</h5>
              </Link>
              <div>
                TYPE: <span>{item.type}</span>
                <br />
                ID: <span>{item.id}</span>
                <br />
                REF: <span>{item.ref}</span>
              </div>

              {isSupportedType(item.type) ? (
                <pre>{getAnswerValue(item.id)}</pre>
              ) : (
                <></>
              )}
              <Divider />
            </div>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomQuestionnairePreviewSteps;
