/* eslint-disable no-console */
import { useBloc as useBloc2 } from "@blac/react";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import reportErrorSentry from "src/lib/reportErrorSentry";
import QuestionnaireCubit from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import QuestionnairePreviewCubit from "src/state/QuestionnairePreviewCubit/QuestionnairePreviewCubit";
import { StorageController } from "src/state/StorageBloc/StorageBloc";
import { useBloc } from "src/state/state";
import CustomQuestionnaire from "src/ui/components/CustomQuestionnaire/CustomQuestionnaire";
import CustomQuestionnairePreviewSteps from "src/ui/components/CustomQuestionnairePreview/CustomQuestionnairePreviewSteps";
import { formatPlaceholders } from "src/ui/components/CustomQuestionnairePreview/helpers";
import useQuestionnaireTracking from "src/ui/components/CustomQuestionnairePreview/useQuestionnaireTracking";
import Link from "src/ui/components/Link/Link";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider
} from "src/ui/styled/X";
import { CenterQuestionnaireContent } from "../AppQueryPopups/dialogs/LifestyleProgramDialog";
import { Button } from "@9amhealth/shared";

interface WrapperProps {
  children: React.ReactNode;
  isLifestyleQuestionnaire: boolean;
}

const Wrapper: React.FC<WrapperProps> = ({
  children,
  isLifestyleQuestionnaire
}) => {
  return isLifestyleQuestionnaire ? (
    <CenterQuestionnaireContent style={{ display: "flex" }}>
      {children}
    </CenterQuestionnaireContent>
  ) : (
    <>{children}</>
  );
};

let isLifestyleQuestionnaireGlobal = window.location.search.includes("lsq=true");

export const SectionTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
`;

const VarsInput = () => {
  const initial = useRef(sessionStorage.getItem("vars") ?? "{}");
  const handleIntitialVarsChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const { value } = e.target;
    try {
      JSON.parse(value);
      sessionStorage.setItem("vars", value);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <label>Intitial Vars</label>
      <div>
        <textarea
          style={{ padding: 10, fontSize: 10 }}
          onChange={handleIntitialVarsChange}
          defaultValue={initial.current}
        ></textarea>
      </div>
    </>
  );
};

const CustomQuestionnaireDebugData: FC = () => {
  const [{ openViews }, { toggleView, isViewOpen }] = useBloc2(
    QuestionnairePreviewCubit
  );
  const [
    { formMeta = {}, endScreens, logic, fields },
    { customFormVariables, generateOutputData }
  ] = useBloc(QuestionnaireCubit);
  const location = useLocation();

  const accordionProps = useCallback(
    (name: string): Record<string, unknown> => {
      return {
        expanded: isViewOpen(name),
        onChange: (): void => toggleView(name)
      };
    },
    [openViews]
  );

  const {
    title,
    type,
    id: formId
  } = formMeta as Record<string, string[] | string>;

  const [answersJson, setAnswersJson] = useState({});

  const activeFieldLogic = useMemo(() => {
    const ref = location.hash.replace("#", "");
    const logicItems = logic.filter((item) => item.ref === ref);
    return JSON.stringify(logicItems, null, 2);
  }, [location.hash, logic]);

  const activeFieldData = useMemo(() => {
    const ref = location.hash.replace("#", "");
    const selected = fields.find((item) => item.ref === ref);
    return JSON.stringify(selected ?? {}, null, 2);
  }, [location.hash, fields]);

  const meta = [
    { title: "Title", data: title as string },
    { title: "Type", data: type as string },
    { title: "Form ID", data: formId as string }
  ];

  const [isLifestyleQuestionnaire, setIsLifestyleQuestionnaire] = useState(
    isLifestyleQuestionnaireGlobal
  );

  return (
    <div>
      <div
        style={{
          position: "fixed",
          left: 10,
          top: 0,
          width: 500,
          display: "flex",
          justifyContent: "space-between"
        }}
      ></div>

      <Box
        style={{
          marginLeft: 20,
          width: 500,
          paddingRight: 20
        }}
      >
        <Box>
          <div style={{ paddingTop: 15 }}></div>
        </Box>
        <VarsInput />
        <Accordion {...accordionProps("meta")}>
          <AccordionSummary>
            <SectionTitle>Meta Data</SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              {meta.map((item) => (
                <div key={item.title} style={{ paddingTop: 15 }}>
                  <h5>{item.title}</h5>
                  <pre>{item.data}</pre>
                  <Divider />
                </div>
              ))}
              <a
                style={{ color: "#000" }}
                href={`https://admin.typeform.com/form/${formId.toString()}/create`}
                target="_blank"
                rel="noreferrer"
              >
                Open in TypeForm
              </a>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion {...accordionProps("vars")}>
          <AccordionSummary>
            <SectionTitle>
              Variables ({Object.keys(customFormVariables).length})
            </SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              {Object.keys(customFormVariables).map((item: string) => {
                return (
                  <div key={item} style={{ paddingTop: 15 }}>
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1 }}>
                        <h5>{item}</h5>
                        <pre>
                          <>{typeof customFormVariables[item]} - </>
                          <>{customFormVariables[item] ?? "--"}</>
                        </pre>
                      </div>
                    </div>
                    <Divider />
                  </div>
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>

        <CustomQuestionnairePreviewSteps
          accordionProps={accordionProps("steps")}
        />

        <Accordion {...accordionProps("logic")}>
          <AccordionSummary>
            <SectionTitle>Selected step logic</SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              <pre>{activeFieldLogic}</pre>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion {...accordionProps("stepdata")}>
          <AccordionSummary>
            <SectionTitle>Selected step data</SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              <pre>{activeFieldData}</pre>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion {...accordionProps("endscreens")}>
          <AccordionSummary>
            <SectionTitle>End Screens ({endScreens.length})</SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              {endScreens.map((item) => (
                <div
                  key={item.id}
                  style={{
                    paddingTop: 15,
                    ...(location.hash === `#${item.ref}`
                      ? {
                          borderLeft: `4px solid orange`,
                          paddingLeft: 10
                        }
                      : {})
                  }}
                >
                  <Link style={{ color: "#000" }} to={`#${item.ref}`}>
                    <h5>{formatPlaceholders(item.title)}</h5>
                  </Link>

                  <Divider />
                </div>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion {...accordionProps("output")}>
          <AccordionSummary>
            <SectionTitle>Output Data</SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Button onPress={() => setAnswersJson(generateOutputData)}>
              Generate
            </Button>
            <Box width="100%">
              <pre>{JSON.stringify(answersJson, null, 2)}</pre>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion {...accordionProps("tools")}>
          <AccordionSummary>
            <SectionTitle>Tools</SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              <nine-button
                onClick={(): void => {
                  StorageController.clear();
                  window.location.reload();
                }}
              >
                Clear All Cache
              </nine-button>
            </Box>
            <Box width="100%" style={{ marginTop: "24px" }}>
              <label>
                <input
                  type="checkbox"
                  checked={isLifestyleQuestionnaire}
                  onChange={() => {
                    setIsLifestyleQuestionnaire(!isLifestyleQuestionnaire);
                    isLifestyleQuestionnaireGlobal = !isLifestyleQuestionnaire;
                  }}
                />
                <span style={{ marginLeft: "4px", fontSize: "1.25rem" }}>
                  Is Lifestyle Questionnaire
                </span>
              </label>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
};

const AddPartnerToCustomFormVariables: FC = () => {
  const [, { customFormVariables }] = useBloc(QuestionnaireCubit);

  const [queryParams] = useSearchParams();
  const partner = queryParams.get("partner");

  useEffect(() => {
    if (partner?.length) {
      try {
        const decodedPartner = decodeURIComponent(partner ?? "");
        customFormVariables.partner = decodedPartner;
      } catch (error) {
        reportErrorSentry(error);
      }
    }
  }, [partner]);

  return null;
};

const CustomQuestionnairePreview: FC<{ iframe?: boolean }> = ({
  iframe = false
}) => {
  const [state] = useBloc2(QuestionnairePreviewCubit);

  const { id = "" } = useParams<{ id: string }>();

  const preview = !iframe;

  useQuestionnaireTracking();

  const [isLifestyleQuestionnaire, setIsLifestyleQuestionnaire] = useState(
    isLifestyleQuestionnaireGlobal
  );

  useEffect(() => {
    setIsLifestyleQuestionnaire(isLifestyleQuestionnaireGlobal);
  }, [isLifestyleQuestionnaireGlobal]);

  return (
    <div
      key={state.key}
      style={
        {
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: iframe ? "center" : "flex-start",
          alignItems: iframe ? "center" : "flex-start",
          background: iframe ? "#F2EFE7" : "#FFFAF2",
          overflow: "auto"
        } as React.CSSProperties
      }
    >
      <Wrapper isLifestyleQuestionnaire={isLifestyleQuestionnaire}>
        <CustomQuestionnaire
          disableTracking
          preview={preview}
          id={id}
          useRouting
          // onStep={(e, i) => console.log("step", e, i)}
          // onDataSent={(e) => console.log("data sent", e)}
          // onQuestionnaireData={(e) => console.log("questionnaire data", e)}
          // onLastStepCompleted={() => console.log("last step completed")}
        >
          <AddPartnerToCustomFormVariables />
          {!iframe && (
            <div
              className="hide-mobile"
              style={{
                position: "relative",
                height: "calc(100vh)",
                paddingTop: 60,
                minWidth: 520,
                paddingBottom: 20,
                boxShadow: "2px 2px 10px rgba(0,0,0,.2)",
                overflow: "auto"
              }}
            >
              <CustomQuestionnaireDebugData />
            </div>
          )}
        </CustomQuestionnaire>
      </Wrapper>
    </div>
  );
};

export default CustomQuestionnairePreview;
